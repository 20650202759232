<template>
    <div id="summary-page">
        <div class="order-summary">
            <h3>ملخص الطلب</h3>
            <div class="order-box">
                <div class="row box-header">
                    <div class="col-8 text-orange text-right">
                        نوع الإشتراك
                    </div>
                    <div class="col-4 text-center bold text-black">
                        {{ summary.plan_name }}
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-8 text-right">
                            اجمالي المبلغ
                        </div>
                        <div class="col-4 text-small text-center text-gray">
                            <span class="amount">{{ summary.plan_price }}</span>
                            <span class="currancy">ر.س</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 text-right">
                            اشتراك سنوي
                        </div>
                        <div class="col-4 text-small text-center text-black">
                            <span class="d-inline-block ml-1">-</span>
                            <span class="amount">{{ summary.previous_subscription_total }}</span>
                            <span class="currancy">ر.س</span>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-8 text-right">
                            كوبون الخصم
                        </div>
                        <div class="col-4 text-small text-center">
                            <div>
                                <span class="d-inline-block ml-1">-</span>
                                <span class="amount">{{ summary.coupon_discount?summary.coupon_discount:0 }}</span>
                                <span class="currancy">ر.س </span>
                            </div>
                            
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-8 text-right">
                            مجموع الخصم
                        </div>
                        <div class="col-4 text-small text-center text-black">
                            <span class="d-inline-block ml-1">-</span>
                            <span class="amount">{{ summary.total_discount }}</span>
                            <span class="currancy">ر.س</span>
                        </div>

                    </div>
                </div>
                <div class="box-footer">
                    <div class="row">
                        <div class="col-8 text-right">
                            المجموع الإجمالي
                        </div>
                        <div class="col-4 text-center bold">
                            <span class="amount">{{ summary.total }}</span>
                            <span class="currancy">ر.س</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="submit">
            <button 
                type="button" 
                class="btn submit-button btn-block"
                @click='upsell'
                >إكمال عملية الشراء</button>
        </div>
        <loader v-if="loading"/>
    </div>
</template>
<script>
import loader from '@/components/ui/loader'
import { getUpsellSummary, upsell } from '@/apis/services'
export default {
    components: {
        loader
    },
    data() {
        return {
            summary: {
                plan_name: '',
                plan_price: '',
                total_discount: '',
                total: '',
                coupon_discount: ''
            },
            loading: false
        }
    },
    methods: {
        async upsell() {
            try {
                this.loading = true
                let result = await upsell();
                if (result.data.success) {
                    this.loading = false
                    if (result.data.data[0].threeDSecure) {
                        setTimeout( () => {
                            window.location.href = result.data.data[0].redirect_url
                        },200)
                    } else {
                        this.$router.push({path: '/confirmation-page', query: {status: result.data.success ? 'success' : 'failed'}})
                    }
                    
                } else {
                    this.loading = false
                    this.$store.commit('SET_MESSAGE', {
                        message: result.data.message,
                        errors: null,
                        status: result.data.success
                    })
                }
            } catch (e) {
                this.loading = false
            }
        },
        getSummary() {
            this.loading = true
            try {
                getUpsellSummary().then( res => {
                    this.summary = res.data.data
                    this.loading = false
                })
            } catch (e) {
                this.loading = false
            }
        }
    },
    mounted() {
        this.getSummary()
    }
}
</script>
<style lang='scss'>
#summary-page {
   background: #EAEBEE;
    max-width: 50%;
    margin: auto;
    padding-top: 38px;
    @media (max-width: 700px) {
        max-width: 100%;
    } 
    .order-summary {
        .order-box {
            padding-bottom: 34px;
            height: auto;
            .row:nth-child(4) {
                margin-top: 30px;
            }
            .box-footer {
                margin-top: 16px;
            }
        }
        .currancy {
            display: inline-block;
            margin-right: 5px;
        }
    }
}
</style>
